<div class="global-club">
    <div class="heading blue">
        <span class="clickable" routerLink="/global-admin">
            <i class="fa fa-th" aria-hidden="true"></i>
            {{'ADMIN_PANEL.OVERVIEW' | translate}}
        </span>
        <span class="clickable">
            <i class="fa fa-caret-right"></i>
            Club erstellen
        </span>
    </div>
    <div class="panel-content center-content white-background">
        <div class="row">
            <div class="col">
                <h1>Club erstellen</h1>
                <div class="row">
                        <div class="col-6">
                            <app-material-form-input  label="Clubname"
                                                      [text]="clubName"
                                                      (textChange)="clubName = $event"
                                                      (keydown.enter)="createClub()"></app-material-form-input>
                        </div>
                        <div class="col-6 pull-left" >
                            <app-checkbox labelText="Produktiv" [isChecked]="testOnly" (onChange)="testOnly = $event"></app-checkbox>
                        </div>

                    <div class="spacer" style="margin-top: 20px; width: 100%"></div>
                    <!-- Possible Languages -->
                    <div class="col-12 col-sm-6" >
                        <div class="row">
                            <div class="col headerType">
                                <h1 class="selection title">{{'CLUB_ADMIN.LANGUAGE.POSSIBLE_LANGUAGES' | translate}}</h1>
                            </div>
                        </div>
                        <div class="row rowHead" *ngFor="let _language of possibleLanguages">
                            <div class="col" *ngIf="languagesLoaded">
                                <input type="checkbox" [checked]="compareLang.indexOf(_language) > -1"
                                       (change)="togglePossibleLanguage(_language, $event)"/>
                                <label class="checkboxLabel">{{('GENERAL.LANGUAGES.'+ (_language | uppercase) | translate)}}</label>
                            </div>
                        </div>
                    </div>

                    <!-- Default Language -->
                    <div class="col-12 col-sm-6">
                        <div class="row">
                            <div class="col headerType">
                                <h1 class="selection title">{{'CLUB_ADMIN.LANGUAGE.DEFAULT_LANGUAGE' | translate}}</h1>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <label class="dropdown-label">{{'CLUB_ADMIN.LANGUAGE.DEFAULT_LANGUAGE' | translate}}</label>
                                <div class="custom-dropdown" *ngIf="languagesLoaded">
                                    <select (change)="toggleDefaultLanguage($event)">
                                        <option *ngFor="let _language of possibleLanguages" [value]="_language" [selected]="language === defaultLanguage">{{('GENERAL.LANGUAGES.'+ (_language | uppercase) | translate)}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="spacer" style="margin-top: 20px; width: 100%"></div>
                    <!-- Default Country -->
                    <div class="col-12 col-sm-6 spacer">
                        <div class="row">
                            <div class="col headerType">
                                <h1 class="selection title">Default Land</h1>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <label class="dropdown-label">Default Land</label>
                                <div class="custom-dropdown" *ngIf="countriesLoaded">
                                    <select (change)="toggleDefaultCountry($event)">
                                        <option *ngFor="let _country of possibleCountries" [value]="_country.iso31661Alpha2" [selected]="_country.iso31661Alpha2 === defaultCountry">{{_country.name | uppercase}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Possible Countries -->
                    <div class="col-12 col-sm-6" >
                        <div class="row">
                            <div class="col headerType">
                                <h1 class="selection title">Mögliche Länder</h1>
                            </div>
                        </div>
                        <div class="row rowhead" *ngFor="let _country of possibleCountries">
                            <div class="col" *ngIf="countriesLoaded">
                                <input type="checkbox"
                                       (change)="togglePossibleCountry(_country, $event)"/>
                                <label class="checkboxLabel">{{(_country.name | uppercase)}}</label>
                            </div>
                        </div>
                    </div>

                    <!-- Default Theme -->
                    <div class="col-12 col-sm-6">
                        <div class="row">
                            <div class="col headerType">
                                <h1 class="selection title">Default Theme</h1>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <label class="dropdown-label">Default Theme</label>
                                <div class="custom-dropdown">
                                    <select (change)="toggleTheme($event)">
                                        <option value="DEFAULT" selected="true">DEFAULT (N4)</option>
                                        <option value="GDHS-THEME">G-RS</option>
                                        <option value="GETTYGO-THEME">'GettyGo'</option>
                                        <option value="VULCO-THEME">Vulco</option>
                                        <option value="PREMIO-THEME">Premio</option>
                                        <option value="MOTOO-THEME">Motoo</option>
                                        <option value="DRIVER-THEME">Driver</option>
                                        <option value="POINTS-THEME">Point S</option>
                                        <option value="AM-THEME">Automeister</option>
                                        <option value="RTC-THEME">RTC</option>
                                        <option value="PORSCHE-THEME">PORSCHE</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <button class="base-btn success float-right" (click)="createClub()">
                            <i class="fa fa-search"></i>
                            <span>{{'PROMPTS.BUTTONS.SEND' | translate}}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
