<div class="user-menu lang" *ngIf="userService.allow('GLOBAL-ADMIN') || userService.allow('CLUB-ADMIN')">

    <button class="dropdown-btn" (click)="showLangMenu = !showLangMenu; externalNotificationService.showNotifications = false" [ngClass]="{'opened' : showLangMenu}">
        <div class="label">
            {{ themeService.language }}
        </div>
        <i class="fa fa-caret-down"></i>
    </button>

    <ul class="elements" *ngIf="showLangMenu" >
        <li (click)="themeService.changeLanguage('de')">
            <span>DE</span>
            <div class="clearfix"></div>
        </li>
        <li (click)="themeService.changeLanguage('en')">
            <span>EN</span>
            <div class="clearfix"></div>
        </li>
        <li (click)="themeService.changeLanguage('nl')">
            <span>NL</span>
            <div class="clearfix"></div>
        </li>
        <li (click)="themeService.changeLanguage('fr')">
            <span>FR</span>
            <div class="clearfix"></div>
        </li>
        <li (click)="themeService.changeLanguage('pl')">
            <span>PL</span>
            <div class="clearfix"></div>
        </li>
        <li (click)="themeService.changeLanguage('cs')">
            <span>CS</span>
            <div class="clearfix"></div>
        </li>
        <li (click)="themeService.changeLanguage('sk')">
            <span>SK</span>
            <div class="clearfix"></div>
        </li>
        <li (click)="themeService.changeLanguage('es')">
            <span>ES</span>
            <div class="clearfix"></div>
        </li>
        <li (click)="themeService.changeLanguage('bg')">
            <span>BG</span>
            <div class="clearfix"></div>
        </li>
        <li (click)="themeService.changeLanguage('el')">
            <span>EL (greek)</span>
            <div class="clearfix"></div>
        </li>
        <li (click)="themeService.changeLanguage('it')">
            <span>IT</span>
            <div class="clearfix"></div>
        </li>
        <li (click)="themeService.changeLanguage('hu')">
            <span>HU</span>
            <div class="clearfix"></div>
        </li>
        <li (click)="themeService.changeLanguage('pt')">
            <span>PT</span>
            <div class="clearfix"></div>
        </li>
        <li (click)="themeService.changeLanguage('ro')">
            <span>RO</span>
            <div class="clearfix"></div>
        </li>
        <li (click)="themeService.changeLanguage('debug')">
            <span>debug</span>
            <div class="clearfix"></div>
        </li>
    </ul>
</div>
<div class="user-menu themes" *ngIf="userService.allow('GLOBAL-ADMIN')">
    <button class="dropdown-btn" (click)="showThemeMenu = !showThemeMenu; externalNotificationService.showNotifications = false" [ngClass]="{'opened': showThemeMenu}">
        <div class="label">
            {{themeService.currentTheme}}
        </div>
        <i class="fa fa-caret-down"></i>
    </button>

    <ul class="elements" *ngIf="showThemeMenu">
        <li (click)="themeService.changeTheme('DEFAULT'); showThemeMenu = false">
            <span>DEFAULT (N4)</span>
            <div class="clearfix"></div>
        </li>
        <li (click)="themeService.changeTheme('GETTYGO-THEME'); showThemeMenu = false">
            <span>GETTYGO</span>
            <div class="clearfix"></div>
        </li>
        <li (click)="themeService.changeTheme('GDHS-THEME'); showThemeMenu = false">
            <span>G-RS</span>
            <div class="clearfix"></div>
        </li>
        <li (click)="themeService.changeTheme('VULCO-THEME'); showThemeMenu = false">
            <span>Vulco</span>
            <div class="clearfix"></div>
        </li>
        <li (click)="themeService.changeTheme('PREMIO-THEME'); showThemeMenu = false">
            <span>Premio</span>
            <div class="clearfix"></div>
        </li>
        <li (click)="themeService.changeTheme('MOTOO-THEME'); showThemeMenu = false">
            <span>Motoo</span>
            <div class="clearfix"></div>
        </li>
        <li (click)="themeService.changeTheme('DRIVER-THEME'); showThemeMenu = false">
            <span>Driver</span>
            <div class="clearfix"></div>
        </li>
        <li (click)="themeService.changeTheme('POINTS-THEME'); showThemeMenu = false">
            <span>Point S</span>
            <div class="clearfix"></div>
        </li>
        <li (click)="themeService.changeTheme('AM-THEME'); showThemeMenu = false">
            <span>Automeister</span>
            <div class="clearfix"></div>
        </li>
        <li (click)="themeService.changeTheme('RTC-THEME'); showThemeMenu = false">
            <span>RTC</span>
            <div class="clearfix"></div>
        </li>
        <li (click)="themeService.changeTheme('FS-THEME'); showThemeMenu = false">
            <span>First Stop</span>
            <div class="clearfix"></div>
        </li>
        <li (click)="themeService.changeTheme('ANAG-THEME'); showThemeMenu = false">
            <span>ANAG</span>
            <div class="clearfix"></div>
        </li>
        <li (click)="themeService.changeTheme('REIFF-THEME'); showThemeMenu = false">
            <span>REIFF</span>
            <div class="clearfix"></div>
        </li>
        <li (click)="themeService.changeTheme('PORSCHE-THEME'); showThemeMenu = false">
            <span>Porsche</span>
            <div class="clearfix"></div>
        </li>
    </ul>
</div>
<div class="user-menu">

    <button class="dropdown-btn" (click)="showUserMenu = !showUserMenu; externalNotificationService.showNotifications = false" [ngClass]="{'opened' : showUserMenu}">
        <div class="icon">
            <i class="fa fa-user-circle"></i>
        </div>
        <div class="label">
            {{ userService.accountData?.displayName }}
        </div>
        <i class="fa fa-caret-down"></i>
    </button>

    <ul class="elements" *ngIf="showUserMenu" >
        <li (click)="navigate('/company-admin')">
            <span>{{ 'USER_PROFILE.COMPANY_ADMIN' | translate }}</span>
            <div class="clearfix"></div>
        </li>
        <li *ngIf="userService.allow('CLUB-ADMIN')" (click)="navigate('/admin'); configService.getClubConfig()">
            <span>{{ 'USER_PROFILE.CLUB_ADMIN' | translate }}</span>
            <div class="clearfix"></div>
        </li>
        <li *ngIf="userService.allow('CLUB-ADMIN')" (click)="openBETAAdminGUI()">
            <span>{{ 'USER_PROFILE.CLUB_ADMIN_BETA' | translate }}</span>
            <div class="clearfix"></div>
        </li>
        <li *ngIf="userService.allow('GLOBAL-ADMIN')" (click)="navigate('/global-admin')">
            <i class="fa fa-terminal"></i>
            <span>{{ 'USER_PROFILE.GLOBAL_ADMIN' | translate }}</span>
            <div class="clearfix"></div>
        </li>
        <li (click)="logout()">
            <i class="fa fa-sign-out"></i>
            <span>{{ 'HEADER.LOGOUT' | translate }}</span>
            <div class="clearfix"></div>
        </li>
    </ul>
</div>
<div class="user-menu notification-button" [ngClass]="{'noMessages': !(externalNotificationService?.getNotificationCount() > 0)}"
     (click)="externalNotificationService.showNotifications = !externalNotificationService.showNotifications; externalNotificationService.hasNewNotifications = false;">
    <i class="fa fa-envelope"></i>
    <span class="{{externalNotificationService.getNotificationType()}}" *ngIf="externalNotificationService.getNotificationCount() > 0">{{externalNotificationService.getNotificationCount()}}</span>
</div>

<div class="notification-box" [ngClass]="{'showNotifications': externalNotificationService.showNotifications, 'browserIsIE': helperService.browserIsIE()}">
    <div class="notification-content">
        <ng-container *ngIf="externalNotificationService.notificationHolder?.list?.length > 0">
            <ng-container *ngFor="let notification of externalNotificationService.notificationHolder?.list">
                <div class="row {{notification.type}}" *ngIf="!externalNotificationService.isNotificationHidden(notification.id)">
                    <div class="col-10 notification-message">
                        <span>{{notification.description}}</span>
                    </div>
                    <div class="col-2 notification-option text-center clickable" (click)="externalNotificationService.hideNotification(notification)">
                        <i class="fa fa-eye-slash"></i>
                    </div>
                </div>
            </ng-container>

        </ng-container>
        <ng-container>
            <div class="alert alert-info text-center" *ngIf="!(externalNotificationService?.getNotificationCount() > 0)">
                <i class="fa fa-info-circle mr-2"></i>
                <span>{{'NOTIFICATION.NO_NOTIFICATION' | translate}}</span>
            </div>
        </ng-container>
    </div>
</div>
